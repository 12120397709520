import toast from './toast'
import borrowerTaskGroup from './borrowerTaskGroup'
import ChatStore from '~/components/chat/v2/store'
import * as settingsDialogStore from './settingsDialogStore'
import * as privacyPolicyStore from './privacyPolicyStore'

export default {
  toast,
  borrowerTaskGroup,
  settingsDialogStore,
  privacyPolicyStore,
  Chat: ChatStore
}
