import axios from 'axios'
import Sentry from '~/util/sentry'

const createHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'X-CSRF-Token': (document.querySelector('[name=csrf-token]') !== null ? document.querySelector('[name=csrf-token]').content : '')
  }
}

const instance = axios.create()
instance.interceptors.request.use(function (config) {
  config.headers = createHeaders()
  return config
})

instance.interceptors.response.use(null, (err) => {
  if (err && err.response && err.response.status) {
    switch (err.response.status) {
      case 400:
        console.log(err)
        Sentry().captureMessage('Axios call returned 400 Bad Request')
        break
      case 401:
        if (err.response.data?.redirect) {
          window.location.replace(err.response.data.redirect)
        } else {
          // Redirect to /login on 401s if session is stale
          window.location.replace('/logout')
        }
        break
      case 403:
        console.log(err)
        // Swallowing for now until we get the borrower app auth figured out
        // Sentry().captureMessage('Axios call returned 403 Forbidden')
        break
      case 404:
        console.log(err)
        // Swallowing for now until we get the borrower app auth figured out
        // Sentry().captureMessage('Axios call returned 404 Not Found')
        break
      case 500:
        // swallow error, don't send to sentry
        console.log(err)
        break
      case 502:
        // swallow error, logged by platform team
        console.log(err)
        break
      case 503:
        // swallow error, logged by platform team
        console.log('Axios call returned 503 Service Unavailable')
        break
      case 504:
        // swallow error, logged by platform team
        console.log(err)
        break
    }
    return Promise.reject(err)
  }
})

export default instance
