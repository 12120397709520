const state = () => ({
  toastValue: false,
  toastType: undefined,
  toastEvent: undefined,
  toastMessage: '',
  toastDescription: undefined,
  toastActionText: undefined,
  toastIsMobileToast: false,
  toastActionClicked: () => {}
})

const getters = {
  getToast (state) {
    const {
      toastValue: value,
      toastType: type,
      toastEvent: event,
      toastMessage: message,
      toastDescription: description,
      toastActionText: actionText,
      toastIsMobileToast: isMobileToast,
      toastActionClicked: actionClicked
    } = state
    return {
      value,
      type,
      event,
      message,
      description,
      actionText,
      isMobileToast,
      actionClicked
    }
  }
}

const mutations = {
  setToast (state, toast) {
    Object.keys(toast).forEach(k => {
      state[`toast${k.charAt(0).toUpperCase() + k.slice(1)}`] = toast[k]
    })
    state.toastValue = true
  },
  closeToast (state) {
    state.toastValue = false
    state.toastType = undefined
    state.toastEvent = undefined
    state.toastMessage = ''
    state.toastDescription = undefined
    state.toastActionText = undefined
    state.toastIsMobileToast = false
    state.toastActionClicked = () => {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
