export const namespaced = true

export const state = () => ({
  needsDashboardContentReload: false,
  activeSettingsTab: 'edit-account',
  activeMobileSettingsTab: null,
  allTabs: [
    {
      slug: 'edit-account',
      key: 0,
      icon: '$snPerson',
      title: 'Account information',
      mobileLoaded: false
    },
    {
      slug: 'privacy-settings',
      key: 1,
      icon: '$snShieldLock',
      title: 'Privacy settings',
      mobileLoaded: false
    },
    {
      slug: 'recent-activity',
      key: 2,
      icon: '$snList',
      title: 'Recent activity',
      mobileLoaded: false
    }
  ]
})

export const getters = {}

export const actions = {
  reloadDashboardContent ({ commit }, value) {
    commit('SET_NEEDS_DASHBOARD_CONTENT_RELOAD', value)
  },
  activateDesktopTab ({ commit }, index) {
    commit('SET_ACTIVE_DESKTOP_TAB', index)
  },
  activateMobileTab ({ commit }, index) {
    commit('SET_ACTIVE_MOBILE_TAB', index)
  },
  resetMobileTab ({ commit }) {
    commit('RESET_MOBILE_TAB')
  },
  hidePrivacySettingsTab ({ commit }) {
    commit('HIDE_PRIVACY_SETTINGS_TAB')
  }
}

export const mutations = {
  SET_NEEDS_DASHBOARD_CONTENT_RELOAD (state, value) {
    state.needsDashboardContentReload = value
  },
  SET_ACTIVE_DESKTOP_TAB (state, index) {
    state.activeSettingsTab = state.allTabs[index].slug
  },
  SET_ACTIVE_MOBILE_TAB (state, index) {
    state.activeMobileSettingsTab = state.allTabs[index].slug
    state.allTabs[index].mobileLoaded = true
  },
  RESET_MOBILE_TAB (state) {
    state.activeMobileSettingsTab = null
  },
  HIDE_PRIVACY_SETTINGS_TAB (state) {
    if (state.allTabs.some(e => e.slug === 'privacy-settings')) {
      state.allTabs.splice(1, 1)
    }
  }
}
