export const namespaced = true

export const state = () => ({
  showPrivacyPolicyDialog: false
})

export const getters = {}

export const actions = {
  togglePrivacyPolicyDialog ({ commit }) {
    commit('TOGGLE_PRIVACY_POLICY_DIALOG')
  }
}

export const mutations = {
  TOGGLE_PRIVACY_POLICY_DIALOG (state) {
    state.showPrivacyPolicyDialog = !state.showPrivacyPolicyDialog
  }
}
