import { datadogRum } from '@datadog/browser-rum'

export default function ({ packName }) {
  if (process.env.RAILS_ENV === 'development') return

  datadogRum.init({
    applicationId: process.env.DATADOG_APPLICATION_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: packName,
    env: process.env.RAILS_ENV,
    sampleRate: 5,
    trackInteractions: true
  })

  datadogRum.startSessionReplayRecording()
}
