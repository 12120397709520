const state = () => ({
  activeTab: ''
})

const getters = {
  getActiveTab: ({ activeTab }) => activeTab
}

const mutations = {
  setActiveTab (state, newTab) {
    state.activeTab = newTab
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
