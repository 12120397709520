import axios from '~/util/axios'

export default {
  borrowerWebContent (code, userLoanAppGuid = null) {
    let locale
    const pathArray = location.pathname.split('/')
    pathArray.shift()
    if (pathArray[0] === 'en' || pathArray[0] === 'es') { // e.g. .../login/asmith1@banknorth.com
      locale = pathArray[0]
    }

    const queryParams = {
      code: code,
      user_loan_app_guid: userLoanAppGuid,
      locale: locale
    }

    return axios.get('/api/v1/content/borrower_web_content', { params: queryParams })
  }
}
