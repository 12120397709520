const data = require('./icons/selection.json')
const font = 'SimpleNexusIcon'
const prefix = 'sn-icon-'

const icons = {}

for (const icon of data.icons) {
  icons[prefix + icon.properties.name] = `${font} ${prefix}${icon.properties.name}`
}

export default icons
