/* eslint no-console: 0, no-unused-vars: 0, no-undef: "error" */
import Vue from 'vue'

export const store = {
  viewed: [],
  response: null,
  authenticatedData: undefined,
  authGuid: undefined,
  parentOrganization: null,
  servicer: undefined,
  userSelectedSac: undefined,
  userClickedForgotPassword: undefined,
  userEmail: undefined,
  userFirstName: undefined,
  userLastName: undefined,
  userPhone: undefined,
  userRedirectUrl: undefined,
  borrowerWebContent: undefined,
  // fetched when custom domain is present without a sac
  effectiveLogoUrl: undefined,
  effectiveAppIconUrl: undefined,
  defaultCode: undefined,
  defaultSacId: undefined,
  orgLoginSettings: {}
}
